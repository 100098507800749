<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-linkSetup">
          <div class="col-12 page-header pl-4">
            {{ lbl['main-menu-link-setup'] }}
          </div>

          <a-form>
            <div class="row pt-4 mb-4">
              <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="row pt-3">
                      <div class="col-md-12 pl-4">
                        <div class="select-link mb-3">
                          {{ lbl['link-setup-select-link-from'] }}
                        </div>
                        <span class="pl-2 pr-5"
                          ><a-radio-group v-model="radio" @change="changeRadio">
                            <a-radio class="pr-5" :value="1">
                              {{ lbl['line-channel-connection-page'] }}
                            </a-radio>
                            <a-radio class="pr-5" :value="2">{{
                              lbl['line-channel-connection-campaign']
                            }}</a-radio>
                          </a-radio-group></span
                        >
                      </div>
                    </div>

                    <div class="row px-2 py-2 pt-3">
                      <div class="col-md-12">
                        <a-form-item class="field-item">
                          <!-- Page -->
                          <a-select
                            v-decorator="[
                              'connectionPage',
                              {
                                initialValue: this.page,
                                rules: [
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ],
                              },
                            ]"
                            :class="{ 'd-none': isPage }"
                            size="large"
                            :placeholder="
                              this.lbl['miscellaneous-please-select-option']
                            "
                            @change="
                              e => {
                                changePage(e)
                              }
                            "
                          >
                            <a-select-option
                              v-for="(item, index) in connection.page"
                              :key="index"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </a-select-option>
                            {{ connection.page }}
                          </a-select>

                          <!-- Campaign -->
                          <a-select
                            v-decorator="[
                              'connectionCampaign',
                              {
                                initialValue: this.campaign,
                                rules: [
                                  {
                                    required: true,
                                    message: '',
                                  },
                                ],
                              },
                            ]"
                            :class="{ 'd-none': isCampaign }"
                            size="large"
                            :placeholder="
                              this.lbl['miscellaneous-please-select-option']
                            "
                            @change="
                              e => {
                                changeCampaign(e)
                              }
                            "
                          >
                            <a-select-option
                              v-for="(item, index) in connection.campaign"
                              :key="index"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </a-select-option>
                            {{ connection.campaign }}
                          </a-select>
                        </a-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 mb-5">
              <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                <div class="page-languages">
                  <img
                    v-if="!isPhilipines"
                    :src="require('@/assets/images/flag-th.png')"
                    alt=""
                    class="flag-languages"
                  />
                  <img
                    v-if="isPhilipines"
                    :src="require('@/assets/images/flag-ph.png')"
                    alt=""
                    class="flag-languages"
                  />
                  {{ lbl['link-setup-link-as-thai'] }}
                </div>
                <div class="input-group text-center mt-3">
                  <input
                    class="form-control text-center"
                    id="LinkUrlTh"
                    name="LinkUrlTh"
                    readonly="readonly"
                    type="text"
                    :value="urlLinkTh"
                    aria-invalid="false"
                  />
                  <div class="input-group-append">
                    <button
                      class="input-group-text copy-web-hook-url"
                      data-toggle="tooltip"
                      title=""
                      data-clipboard-target="#LinkUrlTh"
                      @click="copyClipboardTh"
                      @mouseover="showTooltipTh"
                    >
                      <a-tooltip
                        slot="suffix"
                        id="tooltipClipboard"
                        :title="titleTooltipTh"
                      >
                        <img
                          :src="require('@/assets/images/content_copy.svg')"
                          alt=""
                          class="content-copy"
                        />
                        {{ lbl['line-channel-connect-copy-button'] }}
                      </a-tooltip>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                <div class="page-languages">
                  <img
                    :src="require('@/assets/images/flag-en.png')"
                    alt=""
                    class="flag-languages"
                  />
                  {{ lbl['link-setup-link-as-english'] }}
                </div>
                <div class="input-group text-center mt-3">
                  <input
                    class="form-control text-center"
                    id="LinkUrlEn"
                    name="LinkUrlEn"
                    readonly="readonly"
                    type="text"
                    :value="urlLinkEn"
                    aria-invalid="false"
                  />
                  <div class="input-group-append">
                    <button
                      class="input-group-text copy-web-hook-url"
                      data-toggle="tooltip"
                      title=""
                      data-clipboard-target="#LinkUrlEn"
                      @click="copyClipboardEn"
                      @mouseover="showTooltipEn"
                    >
                      <a-tooltip
                        slot="suffix"
                        id="tooltipClipboard"
                        :title="titleTooltipEn"
                      >
                        <img
                          :src="require('@/assets/images/content_copy.svg')"
                          alt=""
                          class="content-copy"
                        />
                        {{ lbl['line-channel-connect-copy-button'] }}
                      </a-tooltip>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import Vue from 'vue'
import Cache from '@/helper/Cache.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Header from '@/components/Layout/Header'

export default {
  name: 'LinkSetup',
  mixins: [Mixin],
  components: {
    Header,
  },
  data() {
    return {
      radio: 1,
      appId: '',
      page: '',
      campaign: '',
      isPage: false,
      isCampaign: true,
      connection: {
        page: [],
        campaign: [],
      },
      urlLinkTh: '',
      titleTooltipTh: '',
      urlLinkEn: '',
      titleTooltipEn: '',
      host: window.location.origin,
      isPhilipines: false,
    }
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  methods: {
    config: function () {
      return Vue.bzbsConfig
    },
    app_id: function () {
      return Cache.get('CRMUser').AppId
    },
    init() {
      this.getCRMPlusProfile().then(() => {
        this.getPageList()
        this.getCampaignList()
        this.titleTooltipTh =
          this.lbl['line-channel-connect-webhook-tooltip-before-copy']
        this.titleTooltipEn =
          this.lbl['line-channel-connect-webhook-tooltip-before-copy']
        if (Vue.bzbsConfig.businessRule.webFor.PH) this.isPhilipines = true
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.appId = this.app_id() ? this.app_id() : res.data.AppId
            this.handleLoading(false)
            if (res.data.StoreType == null || res.data.StoreType == '') {
              window.location.href = this.host + '/PlanReward/SetupPlan'
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    changeRadio(e) {
      if (e.target.value == 2) {
        this.isPage = true
        this.isCampaign = false
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getCampaignList()
      } else {
        this.isPage = false
        this.isCampaign = true
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getPageList()
      }
    },
    getPageList() {
      let data = [
        {
          Id: 'home',
          Name: this.lbl['line-channel-select-home'],
        },
        {
          Id: 'profile',
          Name: this.lbl['line-channel-select-profile'],
        },
        {
          Id: 'myrewards',
          Name: this.lbl['line-channel-select-myrewards'],
        },
      ]

      const selected = _.map(data, e => {
        return { id: e.Id, name: e.Name }
      })
      this.connection.page = selected
    },
    getCampaignList() {
      return new Promise(resolve => {
        BzbsSettingsLine.getActiveCampaignListUrl()
          .then(res => {
            console.log('getActiveCampaignListUrl', res)
            const selected = _.map(res.data, e => {
              return { id: e.ID, name: e.Name }
            })
            this.connection.campaign = selected
            resolve(res)
          })
          .catch(error => {
            console.log('getActiveCampaignListUrl error', error)
            resolve(error)
          })
      })
    },
    changePage(e) {
      console.log('changePage : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH) {
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/' +
          e +
          '?app_id=' +
          this.appId +
          '&locale=en'
      }

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=en'
    },
    changeCampaign(e) {
      console.log('changeCampaign : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH) {
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/detail/' +
          e +
          '?app_id=' +
          this.appId +
          '&locale=en'
      }

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=en'
    },
    copyClipboardTh() {
      var copyText = document.getElementById('LinkUrlTh')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltipTh() {
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    copyClipboardEn() {
      var copyText = document.getElementById('LinkUrlEn')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltipEn() {
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
  },
}
</script>

<style lang="scss">
.wrap-linkSetup {
  .flag-languages {
    width: 40px;
    height: auto;
    margin-right: 10px;
  }

  .content-copy {
    width: 19px;
    height: auto;
  }

  .page-languages {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #424242;
  }

  .select-link {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #424242;
  }

  .d-none {
    display: none;
  }

  .ant-form {
    padding: 0 15px;
  }

  .field-column:not(:last-child) {
    border-right-width: 1px;
  }

  .field-item.ant-row.ant-form-item {
    margin-bottom: 15px;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #fafafa;
    opacity: 1;
  }

  .input-group-text {
    background-color: #ffffff;
  }
}
</style>
